import Markdown from "components/elements/Markdown"
import React from "react"

const ArticleContent = ({ content }: { content: string }) => {
  return (
    <div className="max-w-[40rem] mx-auto">
      <Markdown content={content} />
    </div>
  )
}

export default ArticleContent
