import React from "react"
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"

const ShareIcons = ({ url }: { url: string }) => {
  return (
    <div className="flex flex-row gap-2 md:flex-col sticky top-4">
      <FacebookShareButton url={url}>
        <FacebookIcon round size="32px" />
      </FacebookShareButton>

      <TwitterShareButton url={url}>
        <TwitterIcon round size="32px" />
      </TwitterShareButton>
    </div>
  )
}

export default ShareIcons
