import React, { FC } from "react"
import StrapiImage from "components/elements/StrapiImage"
import VideoPlayer from "../VideoPlayer/index"
import ShareIcons from "../ShareIcons"
import { BlogCardProps } from "../BlogCard"
import { convertStrapiBlogComponent } from "utils/strapiBlogComponentConverter"
import SignUpForm from "components/elements/SignUpForm"
import { v4 as uuid } from "uuid"
import BlogRow from "components/layouts/BlogRow"
import Breadcrumbs from "../Breadcrumbs/breadcrumbs"

export interface IArticle {
  title: string
  published_at: string
  image: { url: string }
  type: {
    color: string
    title: string
    handle: string
    videoArticles?: boolean
  }
  articleContent?: any[]
  videoUrl?: string
  blogUrl: string
  recommended?: BlogCardProps[]
  klaviyo: {
    list_id: string
    list_name: string
    title: string
    description: string
    cta: string
  }
}

const Article: FC<IArticle> = ({
  title,
  published_at,
  image,
  type,
  articleContent,
  videoUrl,
  blogUrl,
  recommended,
  klaviyo,
  breadcrumbs,
}) => {
  return (
    <div className="mb-12">
      <div className="flex flex-row justify-center">
        <div className="max-w-[76rem] w-full ">
          <div className="flex flex-row mb-7">
            <Breadcrumbs breadcrumbs={breadcrumbs} type={"blog"} />
          </div>
          <div className="mb-12">
            {videoUrl ? (
              <div>
                <VideoPlayer url={videoUrl} />
              </div>
            ) : (
              <StrapiImage
                image={image}
                className="w-full rounded-2xl max-h-72 object-cover"
                loading="lazy"
              />
            )}
          </div>
          <div className="flex flex-row justify-center relative">
            <div className="max-w-[63rem] w-full">
              <div className="flex flex-row justify-center">
                <div
                  className={`inline-block rounded-full py-2 px-6 mb-4`}
                  style={{ backgroundColor: type.color }}
                >
                  <h4
                    className="uppercase text-sm font-semibold"
                    style={{ color: "#432B25" }}
                  >
                    {type.title}
                  </h4>
                </div>
              </div>

              <div className="mb-11">
                <h1 className="text-center text-5xl leading-[3.375rem] font-extrabold text-idc-title">
                  {title}
                </h1>
              </div>

              <div className="relative">
                <div className="absolute top-0 right-0 md:left-0 h-full w-[4.5rem] md:w-8">
                  <ShareIcons url={blogUrl} />
                </div>

                <div className="max-w-[51.5rem] md:mx-auto">
                  <p className="max-w-[40rem] mx-auto text-idc-subtle text-sm tracking-widest font-semibold uppercase mb-6">
                    {`Posted On ${published_at}`}
                  </p>

                  <div className="">
                    {articleContent
                      ? articleContent.map((component) => (
                          <div key={uuid()}>
                            {convertStrapiBlogComponent(component)}
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-screen-xl mx-auto">
        <SignUpForm
          listId={klaviyo.list_id}
          listName={klaviyo.list_name}
          title={klaviyo.form_title}
          description={klaviyo.form_description}
          cta={klaviyo.form_cta}
        />
      </div>

      {recommended && recommended.length ? (
        <div>
          <BlogRow
            title={`${
              type?.videoArticles ? "Related Videos" : "You may also like"
            }`}
            linkText={`${
              type?.videoArticles ? "See more videos" : "See more articles"
            }`}
            handle={`/blog/${type.handle}`}
            posts={recommended}
          />
        </div>
      ) : null}
    </div>
  )
}

export default Article
