import React from "react"
import { getResourceUrl } from "utils/strapi"

const ArticleImage = ({ url }: { url: string }) => {
  return (
    <div className="my-10">
      <img
        src={getResourceUrl(url)}
        className="w-full max-h-96 object-cover rounded-2xl"
      />
    </div>
  )
}

export default ArticleImage
