import React from "react"
import { getResourceUrl } from "utils/strapi"
import "react-lazy-load-image-component/src/effects/blur.css"

const ArticleDoubleImage = ({
  leftUrl,
  rightUrl,
}: {
  leftUrl: string
  rightUrl: string
}) => {
  return (
    <div className="flex flex-row my-10 justify-between w-full">
      <img
        src={getResourceUrl(leftUrl)}
        className="object-cover rounded-2xl"
        style={{ width: "49%" }}
      />
      <img
        src={getResourceUrl(rightUrl)}
        className="object-cover rounded-2xl"
        style={{ width: "49%" }}
      />
    </div>
  )
}

export default ArticleDoubleImage
