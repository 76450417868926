import React from "react"
import ArticleContent from "components/elements/Article/ArticleContent"
import ArticleImage from "components/elements/Article/ArticleImage"
import ArticleDoubleImage from "components/elements/Article/ArticleDoubleImage"

export const convertStrapiBlogComponent = (component) => {
  const type = component.strapi_component

  switch (type) {
    case "page-content.content":
      return <ArticleContent content={component.content} />
    case "page-content.double-image":
      return (
        <ArticleDoubleImage
          rightUrl={component.right.url}
          leftUrl={component.left.url}
        />
      )
    case "page-content.image":
      return <ArticleImage url={component.image.url} />
    default:
      return <></>
  }
}
