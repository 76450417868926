import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Seo } from "components/seo"
import { Layout } from "components/layouts/Layout/layout"
import Article from "components/elements/Article"
import Container from "components/elements/Container"

export default function ArticleIndex({ location, data: { article, klaviyo } }) {
  const [breadcrumb, setBreadcrumb] = useState([])
  article.blogUrl = location.href

  useEffect(() => {
    if (window && window.location) {
      const slug = window.location.pathname.split("/")

      const home = {
        slug: "/",
        name: "Home",
      }

      const collection = {
        slug: "/blog",
        name: "Barista Blog",
      }
      const currentItem = {
        slug: "",
        name: slug[2].replaceAll("-", " "),
      }

      let breadcrumbArray = []
      breadcrumbArray.push(home)
      breadcrumbArray.push(collection)
      breadcrumbArray.push(currentItem)

      setBreadcrumb(breadcrumbArray)
    }
  }, [])

  return (
    <Layout>
      <Seo
        title={article.title}
        description={article.description}
        image={article?.image?.url}
      />
      <Container>
        <div className="mt-7 mb-10">
          <Article {...article} klaviyo={klaviyo} breadcrumbs={breadcrumb} />
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($handle: String!) {
    article: strapiArticles(handle: { eq: $handle }) {
      id
      description
      handle
      videoUrl
      type {
        handle
        color
        title
        id
        videoArticles
      }
      title
      image {
        url
      }
      published_at(formatString: "MMM DD, YYYY")
      recommended {
        description
        handle
        title
        image {
          url
        }
        type
      }
      articleContent
    }
    klaviyo: strapiKlaviyo {
      id
      list_name
      list_id
      form_cta
      form_description
      form_title
    }
  }
`
